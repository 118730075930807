.banner-text {
    .cmp-text {
        background: $color-bright-gray;
        color: $color-arsenic;
        font-style: italic;
        margin-bottom: 35px;
        padding: 25px;

        @media only screen and (max-width:$tablet) {
            margin: 0 5px 35px 5px;
        }

        @media only screen and (max-width:$mobile) {
            padding: 20px;
            margin: 0 0 35px;
        }

        p {
            margin: 0 0 10px;
            line-height: 1.5rem;

            &:last-child {
                margin-bottom: 0
            }
        }
    }
}