.embed.video-youtube {

  .cmp-embed__youtube {
    position: relative;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }
  }

  .cmp-embed--thumbnail {
    position: relative;
    height: 0;

      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-radius: 16px;
        overflow: hidden;
      }
  }

  .hidden {
    img {
      opacity: 0;
      height: 0;
      transition-delay: 0s, 800ms;
    }
  }

  .cmp-embed__load-button {
    display: flex;
    cursor: pointer;

    span {
      background-image: url("data-url:resources/images/general/icons/you-tube-play-icon.svg");
      background-repeat: no-repeat;
      background-size: 40%;
      background-position: 28px;
      width: 35px;
      height: 35px;
      background-color: #fff;
      z-index: 9;
      border-radius: 50%;
      padding: 40px;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);

      &:hover {
        background-color: $color-light-gray;
      }
    }
  }

  .cmp-embed--thumbnail.hide-play-icon {

    .cmp-embed__load-button {
      span {
        display: none;
      }
    }
  }
}