
//== Font
$font-size: 16px;
$font-height: 20px;

$color-text: #757f87;
$color-red: #da4d47;
$color-black-shadow: rgba(75, 74, 74, 0.5);
$color-grey: #757f87;
$color-white: #fff;
$color-black: black;
$color-arsenic: #3c4348;
$color-bright-gray: #ebebeb;
$color-light-gray : #d4d4d4;

$mobile: 767px;
$tablet: 1023px;
$laptop: 1200px; 
