.main-text {

    >.cmp-text {
        margin-bottom: 10px;

        p {
            color: $color-grey;
            @include OpenSans-regular;
            font-size: 16px;
            margin-bottom: 10px;
            padding: 0;
        }

        a {
            color: $color-text;

            &:hover {
                color: $color-red;
            }
        }

        b {
            @include OpenSans-bold;
        }
    }

    ul {
        list-style-type: disc;
        padding-left: 40px;
        margin: 16px 0;

        @media only screen and (max-width:$tablet) {
            padding-left: 30px;
        }

        ul {
            list-style-type: circle;
            margin: 0;

            @media only screen and (max-width:$tablet) {
                padding-left: 40px;
            }
        }

        li {
            margin-top: 5px;
        }
    }
}