.container-button-icons {
  justify-content: center;
  display: flex;

  .cmp-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: $mobile) {
      flex: auto;
    }

    .button {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      flex: 0 0 calc(100% / 2);

      @media screen and (min-width: $mobile) and (max-width: $tablet) {
        flex: 0 0 calc(100% / 3);
      }

      @media screen and (min-width: $tablet) {
        flex: 0 0 calc((100% / 6) - 1px);
        border-right: 1px solid #d6d7d9;

        &:last-child {
          border-right: none;
        }
      }
    }

    .cmp-button {
      text-decoration: none;
      border: none;
      margin: 0.5em;

      .cmp-button__icon {
        display: block;
        min-height: 50px;
        padding: 15px 0 0 0;
      }

      .cmp-button__icon--shoppers {
        background: url("data-url:resources/images/icons/shopper.svg");
      }

      .cmp-button__icon--insights {
        background: url("data-url:resources/images/icons/insight.svg");
      }

      .cmp-button__icon--sustainability {
        background: url("data-url:resources/images/icons/sustainability.svg");
      }

      .cmp-button__icon--technology {
        background: url("data-url:resources/images/icons/technology.svg");
        transform: rotate(40deg);
      }

      .cmp-button__icon--management {
        background: url("data-url:resources/images/icons/management.svg");
      }

      .cmp-button__icon--employees {
        background: url("data-url:resources/images/icons/employee.svg");
      }

      .cmp-button__icon {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 30px;
      }

      &:hover {
        .cmp-button__text {
          color: #da4d47;
          transition: color 0.3s ease;
        }

        .cmp-button__icon--shoppers {
          background: url("data-url:resources/images/icons/shopper-hover.svg");
        }

        .cmp-button__icon--insights {
          background: url("data-url:resources/images/icons/insight-hover.svg");
        }

        .cmp-button__icon--sustainability {
          background: url("data-url:resources/images/icons/sustainability-hover.svg");
        }

        .cmp-button__icon--technology {
          background: url("data-url:resources/images/icons/technology-hover.svg");
          transform: rotate(40deg);
        }

        .cmp-button__icon--management {
          background: url("data-url:resources/images/icons/management-hover.svg");
        }

        .cmp-button__icon--employees {
          background: url("data-url:resources/images/icons/employee-hover.svg");
        }

        .cmp-button__icon {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 30px;
          transition: 0.3s ease;
        }
      }

      .cmp-button__text {
        color: #a4a9ad;
        @include RopaSans-regular;
        font-size: 21px;
        padding: 0 0 25px 0;
        text-transform: uppercase;
        font-weight: 300;

        @media only screen and (max-width: $laptop) {
          font-size: 16px;
        }
      }
    }
  }
}
