/*== Font loading */

@font-face {
  font-family: Oswald Bold;
  src: url("resources/fonts/Oswald/Oswald-Bold.woff2") format('woff2');
  font-style: normal;
}

@mixin Oswald-bold {
  font-family: Oswald Bold, sans-serif; 
  font-style: normal;
}

@font-face {
  font-family: Oswald Light;
  src: url("resources/fonts/Oswald/Oswald-Light.woff2") format('woff2');
  font-style: normal;
}

@mixin Oswald-light {
  font-family: Oswald Light, sans-serif;
  font-style: normal;
}

@font-face {
  font-family: Oswald Regular;
  src: url("resources/fonts/Oswald/Oswald-Regular.woff2") format('woff2');
  font-style: normal;
}

@mixin Oswald-regular {
  font-family: Oswald Regular, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Ropa Sans;
  src: url("resources/fonts/RopaSans/RopaSans-Regular.woff2") format('woff2');
  font-weight: normal;
  font-style: normal;
}

@mixin RopaSans-regular {
  font-family: Ropa Sans, sans-serif;
  font-weight: 300;
  font-style: normal; 
}

@font-face {
  font-family: Ropa Sans;
  src: url("resources/fonts/RopaSans/RopaSans-Regular.woff2") format('woff2');
  font-weight: normal;
  font-style: normal;
}

@mixin RopaSans-bold {
  font-family: Ropa Sans, sans-serif;
  font-weight: 700;
  font-style: normal; 
}

@font-face {
  font-family: Open Sans Bold;
  src: url("resources/fonts/OpenSans/OpenSans-Bold.woff2") format('woff2');
  font-weight: 700;
  font-style: normal;
}

@mixin OpenSans-bold {
  font-family: Open Sans Bold;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Open Sans;
  src: url("resources/fonts/OpenSans/OpenSans-Regular.woff2") format('woff2');
  font-weight: 400;
  font-style: normal;
}

@mixin OpenSans-regular {
  font-family: Open Sans; 
  font-weight: 400;
  font-style: normal; 
}