.container-hero {
  .cmp-container {
    background-position: top center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;

    @media screen and (min-width: $mobile) {
      min-height: 550px;
    }
  }

  .cmp-text {
    text-align: center;
    text-transform: uppercase;
    line-height: normal;

    h1,
    h2,
    h3 {
      color: white;
    }

    h1,
    h2 {
      margin: 0;
      letter-spacing: 2px;
    }

    h2 {
      @include RopaSans-regular;
      font-size: 35px;

      @media screen and (min-width: $mobile) {
        font-size: 40px;
      }
    }

    h1 {
      @include Oswald-regular;
      font-size: 45px;

      @media screen and (min-width: $mobile) {
        font-size: 60px; 
      }
    }

    h3 {
      @include Oswald-light;
      border-top: 2px solid #d3d1cf;
      font-size: 25px;
      letter-spacing: 1px;
      margin-top: 20px;
      padding: 20px 15px 0 15px;

      @media screen and (min-width: $mobile) {
        font-size: 35px;
      }
    }
  }
}
