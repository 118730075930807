.container-social-buttons {
    >.cmp-container {
      display: flex;
      flex-wrap: wrap;
      padding: 20px 0 15px 0;

      .text {
        flex-basis: 100%;
      }

      .cmp-text {
        color: #757f87;
        margin: 0;

        p {
          @include OpenSans-bold;
          font-size: 13px;
          margin: 5px 0;
        }
      }

      .cmp-button {
        border: none;
        padding: 0;
        margin: 0 14px 0 0;

        .cmp-button__icon {
          display: inline-block;
          width: 25px;
          height: 25px;
          background-color: #768188;
          background-repeat: no-repeat;
          background-size: 15px;
          background-position: center;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #f08b21;
          }
        }

        .cmp-button__icon--linkedin {
          background-image: url("data-url:resources/images/general/icons/linkedin-icon.svg");
        }

        .cmp-button__icon--x-social {
          background-image: url("data-url:resources/images/general/icons/x-icon.svg");
        }

        .cmp-button__icon--facebook {
          background-image: url("data-url:resources/images/general/icons/facebook-icon.svg");
        }

        .cmp-button__icon--googleshare {
          background-image: url("data-url:resources/images/general/icons/googleshare-icon.svg");
        }

        .cmp-button__icon--wechat {
          background-image: url("data-url:resources/images/general/icons/weshare-icon.svg");
        }

        .cmp-button__icon--mail {
          background-image: url("data-url:resources/images/general/icons/mail-icon.svg");
        }
      }
    }
  }