//== Defaults

html,
body {
  margin: 0;
  @include OpenSans-regular;
  color: $color-text;
  font-size: $font-size;
}

a {

}

p {
  margin-block: 0.75rem;
  line-height: 28px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

button {
  border: none;
  background: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

img {
  width: 100%;
  height: auto;
}

//== Grid

$max_col: 12;

// Default breakpoint
.aem-Grid {
  @include generate-grid(default, $max_col);
}

// Phone breakpoint
@media (max-width: 768px) {
  .aem-Grid {
    @include generate-grid(phone, $max_col);
  }
}