.xf-header {

    .cmp-experiencefragment--header {
        position: relative;

        .cmp-container {
            max-width: 1400px;
            width: 100%;
            margin-inline: auto;
            padding-inline: 15px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }

        .adaptiveImage {
            height: auto;
            max-width: 470px;
            width: 100%;
            flex-basis: 65%;

            .cmp-image {
                margin-top: 20px;
            }
        }

        #mobile-menu {
            padding: 0;
            border: none;
            background-image: url("data-url:resources/images/general/icons/hamburger-icon.svg");
            width: 30px;
            height: 30px;
            display: block;
            background-size: 100%;
            background-repeat: no-repeat;
            margin: 20px 10px 0 40px;
            cursor: pointer;

            &.cross {
                background-image: url("data-url:resources/images/general/icons/close-icon.svg");
            }

            @media screen and (min-width:calc($mobile + 1px)) {
                display: none;
            }
        }

        .text-navigation-header {
            background: rgba(87, 92, 99, 0.8);
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            width: 100%;
            height: 85px;

            @media only screen and (max-width:$mobile) {
                display: none;
            }

            &.is-open {
                @media only screen and (max-width:$mobile) {
                    background: #3c4248;
                    display: block;
                    left: 0;
                    padding: 15px 15px 15px 20px;
                    position: absolute;
                    top: 0;
                    width: 235px;
                    height: auto;
                }
            }

            .cmp-text {
                margin: 0;
                width: 100%;
                height: 100%;
                max-width: 1400px;
                margin-inline: auto;
            }

            ul:first-child {
                margin: 0;
                padding: 0;

                @media only screen and (min-width:calc($mobile + 1px)) {
                    height: 100%;
                    display: flex;
                    align-items: center; 
                }

                > li:first-child a {
                    padding: 15px;

                    @media only screen and (max-width:$mobile) {
                        padding: 5px 0 0 0;
                    }
                }
            }

            ul li {
                @media only screen and (max-width:$mobile) {
                    line-height: 1.7rem;
                    display: block;
                }

                a.active,
                ul li a.active {
                    color: #eb6d24;
                }

                a {
                    @include RopaSans-regular; 
                    font-size: 23px;
                    color: white;
                    display: inline-block;
                    padding: 15px 50px;
                    text-decoration: none;
                    text-transform: uppercase;

                    @media (min-width:1000px) and (max-width:1220px) {
                        padding: 15px 30px;
                    }

                    @media only screen and (max-width:$tablet) {
                        padding: 20px 20px 20px 0;
                    }

                    @media only screen and (max-width:$mobile) {
                        padding: 0;
                        font-size: 16px;
                    }
                }

                a:hover,
                ul li a:hover {
                    color: #eb6d24;
                    transition: 0.3s ease;
                }
            }

            ul li > ul {
                display: none;
                background: #3c4248;
                padding: 20px 0;
                position: absolute;
                width: 255px;

                li {
                    line-height: 1.5rem;
                    @media only screen and (max-width:$mobile) {
                        margin-bottom: 10px;
                        margin-right: 0;
                    }
                }

                @media only screen and (max-width:$tablet) {
                    width: 180px;
                }

                @media only screen and (max-width:$mobile) {
                    display: block;
                    position: inherit;
                    padding: 0 0 0 30px;
                }
            }

            ul li > ul li a {
                padding: 10px 20px;

                @media only screen and (max-width:$mobile) {
                    padding: 0;
                }
            }

            ul li:hover > ul {
                display: block;
            }
        }
    }
}