//Footer Styles
.xf-footer {

  .cmp-experiencefragment--footer {
    background-color: #b5b9bd;
    padding: 20px 0;

    >.cmp-container {

      max-width: 1400px;
      margin-inline: auto;
      padding-inline: 15px;

      #copyright p {
        @include OpenSans-regular;
        font-size: 10px;
        line-height: 1rem;
        margin-top: 2px;
      }

      .text-navigation-footer {
        .cmp-text {
          padding-top: 10px;
        }


        ul {
          padding: 0;
          margin: 0;
          display: flex;
          flex-wrap: wrap;

          li {
            @include OpenSans-bold;
            display: block;
            line-height: 2rem;
            padding: 0 20px 0 0;

            a {
              color: white;
              font-size: 16px;
              text-decoration: none;
              text-transform: uppercase;
              transition: color 0.3s ease;

              &:hover {
                color: #eb6d24;
              }
            }

            @media only screen and (min-width: $mobile) {
              padding: 0 50px 0 0;
            }
          }
        }
      }
    }
  }
}