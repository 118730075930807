.button-pdf-download {

    .cmp-button {
        margin: 0;
        position: relative;
        background-color: $color-red;
        border: none;
        border-radius: 0;
        padding: 4px 12px 4px 33px;
        margin-top: 20px;
        color: $color-white;
        text-decoration: none;
        background-image: url("data-url:resources/images/general/icons/file-icon.svg");
        background-repeat: no-repeat;
        background-position: 8px;
        background-size: 18px;
        filter: drop-shadow(1px 7px 2px $color-black-shadow);

        .cmp-button__text {
            @include OpenSans-regular;
            font-size: 15px;
            text-transform: uppercase;
        }

        &:before {
            content: "";
            width: 30px;
            height: 100%;
            position: absolute;
            top: 0;
            right: -26px;
            background-color: $color-red;
            clip-path: polygon(0% -3%, 0% 100%, 65% 100%, 18% 47%, 68% 0%);
        }
    }
}