.container-bg-area {
  background-image: url("resources/images/area-image.jpg");
  padding: 35px 0 40px 0;

  > .cmp-container {
    padding-bottom: 0;
  }
}

.container-bg-boxes {
  background: url("resources/images/background-reports.jpg");
  padding: 35px 0 5px 0;
  border-top: 1px solid #b9bdbd;
}

.container-bg-dots {
  background: url("resources/images/background-study.png");
}
