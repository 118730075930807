.title-section {
    .cmp-title__text {
        color: $color-red;
        @include RopaSans-bold;
        font-size: 29px;
        margin: 0 0 40px 0;
        text-align: center;
        text-transform: uppercase;
        line-height: 2rem;
    }
}
