
//== Variables

@import 'site/variables';
@import 'site/grid';

//== Site global

@import 'npm:normalize.css';
@import 'site/fonts';
@import 'site/base';

//== Core components
@import 'components/button/button_pdf_download.scss';

@import 'components/text/_text.scss';
@import 'components/text/_text_main.scss'; 
@import 'components/text/_text_banner.scss';

@import 'components/title/_title.scss';
@import 'components/title/_title_styles.scss';
@import 'components/title/_title_section.scss';

@import 'components/teaser/_teaser.scss';

//== Lists and Navigation components

@import 'components/search/_search.scss';

@import 'components/separator/_separator.scss';
@import 'components/separator/_separator_standard.scss';
@import 'components/separator/_separator_large.scss';
@import 'components/separator/_separator_extra_large.scss';





//== Container components

@import 'components/container/_container_button_icon.scss';
@import 'components/container/_container_hero.scss';
@import 'components/container/_container_main_content.scss';
@import 'components/container/_container_social_buttons.scss';
@import 'components/container/_container_backgrounds.scss';



//== Fragments (CF + XF) components

@import 'components/experiencefragment/_experiencefragment_footer.scss';
@import './components/experiencefragment/_experiencefragment_header.scss'; 

//== Social Sharing component

@import 'components/socialmediasharing/_social_media_sharing.scss';

//== Embed > YouTube component

@import 'components/embed/_embed_youtube.scss';
